<template>
  <div>
    <b-modal
      id="reservation-search-by-eticket-modal"
      header-bg-variant="light-info"
      centered
      size="md"
      @show="handleShowModal"
    >
      <template v-slot:modal-header>
        <h4 class="text-heading-3 m-0">
          Xem số vé
        </h4>
      </template>
      <template v-slot:modal-footer="{ close }">
        <b-button
          variant="outline-danger"
          pill
          @click="close()"
        >
          {{ $t('back') }}
        </b-button>
        <b-button
          variant="gradient"
          pill
          @click="handleOpenViewEticketModal"
        >
          {{ $t('search') }}
        </b-button>
      </template>
      <b-form ref="form">
        <b-row class="mx-0">
          <b-col
            cols="12"
            md="12"
          >
            <b-form-group
              label="Nhập vào số vé"
              label-for="ticket-num"
            >
              <b-form-input
                id="ticket-num"
                v-model="ticketNumber"
                v-remove-non-numeric-chars.allNumber
                :formatter="trimInput"
                class="mx-1"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>

    <ModalViewEticket
      v-if="ticketData"
      :ticket-data.sync="ticketData"
      :is-out-ticket="true"
      :current-arr-ticket-length="currentArrTicketLength"
    />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BModal,
  BForm,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import { trimInput } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BModal,
    BButton,
    BFormInput,
    BForm,

    ModalViewEticket: () => import('@reservation/reservation-modify/components/detail/eticket-details/ModalViewEticket.vue'),
  },
  props: {
    currentArrTicketLength: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const ticketNumber = ref(null)
    const ticketData = ref(null)

    async function handleOpenViewEticketModal() {
      await new Promise(resolve => {
        const payload = {
          number: ticketNumber.value,
        }

        if (ticketNumber.value.startsWith('73824')) {
          payload.ticketType = 'TKT'
        } else {
          payload.ticketType = 'EMD'
        }

        ticketData.value = payload
        resolve()
      })

      this.$bvModal.show('modal-modify-view-eticket-details')
      this.$bvModal.hide('reservation-search-by-eticket-modal')
    }

    function handleShowModal() {
      ticketNumber.value = null
      ticketData.value = null
    }

    return {
      ticketData,
      ticketNumber,
      handleOpenViewEticketModal,
      handleShowModal,
      trimInput,
    }
  },
}
</script>
