var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "reservation-search-by-eticket-modal",
      "header-bg-variant": "light-info",
      "centered": "",
      "size": "md"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('h4', {
          staticClass: "text-heading-3 m-0"
        }, [_vm._v(" Xem số vé ")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          attrs: {
            "variant": "outline-danger",
            "pill": ""
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('back')) + " ")]), _c('b-button', {
          attrs: {
            "variant": "gradient",
            "pill": ""
          },
          on: {
            "click": _vm.handleOpenViewEticketModal
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('search')) + " ")])];
      }
    }])
  }, [_c('b-form', {
    ref: "form"
  }, [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nhập vào số vé",
      "label-for": "ticket-num"
    }
  }, [_c('b-form-input', {
    directives: [{
      name: "remove-non-numeric-chars",
      rawName: "v-remove-non-numeric-chars.allNumber",
      modifiers: {
        "allNumber": true
      }
    }],
    staticClass: "mx-1",
    attrs: {
      "id": "ticket-num",
      "formatter": _vm.trimInput
    },
    model: {
      value: _vm.ticketNumber,
      callback: function callback($$v) {
        _vm.ticketNumber = $$v;
      },
      expression: "ticketNumber"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.ticketData ? _c('ModalViewEticket', {
    attrs: {
      "ticket-data": _vm.ticketData,
      "is-out-ticket": true,
      "current-arr-ticket-length": _vm.currentArrTicketLength
    },
    on: {
      "update:ticketData": function updateTicketData($event) {
        _vm.ticketData = $event;
      },
      "update:ticket-data": function updateTicketData($event) {
        _vm.ticketData = $event;
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }